import { mapActions, mapGetters, mapMutations } from 'vuex'

import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'education-streams-seo',
  computed: {
    ...mapGetters({
      currentItem: 'educationStreams/currentItem',
      seo: 'educationStreams/seo',
      seoLoading: 'educationStreams/seoLoading',
      updateSeoLoading: 'educationStreams/updateSeoLoading'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      this.fetchData({
        course_id: this.$route.params.course_id,
        id: this.currentItem.id
      })
    }

	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
  },
  methods: {
    ...mapActions({
      fetchData: 'educationStreams/GET_SEO',
      update: 'educationStreams/UPDATE_SEO'
    }),
    ...mapMutations({
      setSeo: 'educationStreams/SET_SEO'
    }),
    submit (formData) {
      formData.course_id = this.$route.params.course_id
      formData.stream_id = this.currentItem.id

      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
