export function educationStreamsTableHead () {
  return [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Date at',
      value: 'date_at'
    },
    {
      text: 'Tariffs',
      value: 'tariffs'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
