import { mapGetters } from 'vuex'

import General from '../general/index.vue'
import Seo from '../seo/index.vue'

export default {
  name: 'education-streams-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general'
    }
  },
  components: {
    General,
    Seo
  },
  computed: {
    ...mapGetters({
      isLoading: 'educationStreams/isLoading',
      currentItem: 'educationStreams/currentItem',
	  updateSeoLoading: 'educationStreams/updateSeoLoading'
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'SEO',
          slug: 'seo',
          id: 2,
          disabled: !this.currentItem
        }
      ]
    }
  },
  methods: {
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
    },

    save () {
      this.$refs.general.$emit('event-save')
    }
  }
}
