import mixins from '@/mixins'
import { debounce, find } from 'lodash'

import { mapActions, mapGetters, mapMutations } from 'vuex'

import DialogView from './dialog-view/index.vue'
import Draggable from 'vuedraggable'

import { educationStreamsTableHead } from '@/modules/education/helpers/streams'

export default {
  name: 'education-streams',
  mixin: [mixins],
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      editedItem: null,
      streams: []
    }
  },
  components: {
    DialogView,
    Draggable
  },
  watch: {
    'list' () {
      this.streams = this.list
    }
  },
  computed: {
    ...mapGetters({
      list: 'educationStreams/list',
      isListLoading: 'educationStreams/isListLoading',
      isLoading: 'educationStreams/isLoading',
      accessCountries: 'profile/accessCountries',
      setting: 'courses/setting',
      filterCountries: 'educationActionPlans/filterCountries',
      filterStatus: 'educationActionPlans/filterStatus'
    }),
    educationStreamsTableHead
  },
  created () {
    if (!this.list.length) {
      this.fetchList({ course_id: this.$route.params.course_id }).then(() => {
        this.streams = this.list
      })
    } else {
      this.streams = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'educationStreams/GET_LIST',
      remove: 'educationStreams/DELETE'
    }),
    ...mapMutations({
      setCurrentItem: 'educationStreams/SET_ITEM',
      changeFixBody: 'system/FIX_BODY',
      changeFilter: 'educationStreams/CHANGE_FILTER'
    }),
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeDialogView (item = null) {
      this.setCurrentItem(item)
      this.openDialogView = true
    },
    removeStream () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },

    getCountry (country_id) {
      return find(this.accessCountries, ['id', country_id])
    },

    changeFilterLocal (value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.sendRequest()
    },

    sendRequest: debounce(function () {
      this.fetchList()
    }, 1000)
  }
}
